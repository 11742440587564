<template lang="pug">
Modal(
  width="618"
  :mask-closable="true"
  :closable="false"
  :styles="{top: '50px', margin: '0px auto 50px'}"
  :value="visible"
  @on-visible-change="visibleChange"
  @on-cancel="onClose"
)
  Tabs(:value="currentTab" type="card" @on-click="onChangeTab")
    TabPane(
      v-for="({ name, odds, type, titleMode, description, method, keys, keyName, submenu }, index) in tabs"
      :key="`tabs-${index}`"
      :label="getTabTitle(name, method)"
      :name="name"
      :ref="name"
    )
      .content(v-if="type === 'signalCondition'")
        .con(v-show="currentTab === name || previousTab === name")
          Row(:gutter="20")
            Col(span="18")
              Alert(type="warning" class="tip" v-if="!description") {{ getAlert(name) }}
              Alert(type="warning" class="tip" v-if="description") {{ $t(description) }}
            Col(span="6" style="text-align: right")
              Button.actions(
                type="primary",
                icon="md-add",
                @click="addSignalCondition()"
              ) {{ $t('Add signal') }}
          Row.condition-title(align="middle" :gutter="20" style="margin-bottom: 10px")
            Col.title(span="15")
              h3 {{ $t('signalChanceInNoWin') }}
            Col.val(span="9")
              Input(
                v-model="formData.signalChanceInNoWin"
                :placeholder="$t('signalChanceInNoWin')"
              )
          Row(align="middle")
            Col.title(span="24")
              Divider
          Row(:gutter="20"
            v-for="(condition, idx) in formData[name]"
            :key="`${idx}`"
            align="middle"
            style="margin-bottom: 10px"
          )
            Col(span="5")
              h3(@click="toggleCondition(idx)")
                span {{ `${$t('signalCondition')} ${idx + 1}` }}
                Icon(type="md-arrow-dropdown")
            Col(span="10")
              Slider(
                v-model="condition.hitBox"
                :min="0"
                :max="1000"
                :step="10"
                @on-input="onSliderChange"
                :disabled="viewMode"
              )
            Col(span="4")
              InputNumber(
                :step="10"
                v-model="condition.hitBox"
                :disabled="viewMode"
              )
            Col(span="2")
              span {{ singalPercent(idx)}}
            Col(span="3" style="text-align: right")
              Button(
                type="error",
                icon="md-trash",
                @click="removeSignalCondition(idx)"
              )
            Col.submenu(span="24" v-if="isShowCondition(idx)" style="margin-top: 10px")
              Row.condition-title(align="middle" style="margin-bottom: 10px")
                Col.title(span="10")
                  h3 {{ $t('singalSymbol') }}
                Col.val(span="8" offset="6")
                  Select.select-prefix(v-model="condition.singalSymbol" :key="singalSymbol" :placeholder="$t('')")
                    template(slot="prefix")
                      Avatar(
                        :src="getSymbol(formData.game, condition.singalSymbol)"
                        v-if="condition.singalSymbol > 0"
                        shape="square"
                        size='small'
                      )
                    Option.op(v-for="item in formData.signalSymbolOptions"
                      :value="item"
                      :key="item")
                      Avatar(
                        :src="getSymbol(formData.game, item)"
                        v-if="item > 0"
                        shape="square"
                        size='small'
                      )
                      Col(v-if="item === 0")
                        span {{ $t('No symbol') }}
              Row.condition-title(align="middle" style="margin-bottom: 10px")
                Col.title(span="10")
                  h3 {{$t('singalSymbolAmountAtLeast')}}
                Col.val(span="8" offset="6")
                  Input(
                    v-model="condition.singalSymbolAmountAtLeast"
                    :placeholder="$t('singalSymbolAmountAtLeast')"
                  )
              Row.condition-title(align="middle" style="margin-bottom: 10px")
                Col.title(span="10")
                  h3 {{$t('singalSymbolAmountAtMost')}}
                Col.val(span="8" offset="6")
                  Input(
                    v-model="condition.singalSymbolAmountAtMost"
                    :placeholder="$t('singalSymbolAmountAtMost')"
                  )
              Row.condition-title(align="middle" style="margin-bottom: 10px")
                Col.title(span="10")
                  h3 {{$t('buyFgWinBoxElevateRatio')}}
                Col.val(span="8" offset="6")
                  Input(
                    v-model="condition.buyFgWinBoxElevateRatio"
                    :placeholder="$t('buyFgWinBoxElevateRatio')"
                  )
              Row.condition-row(align="middle")
                Col.title(span="10")
                  h3 {{$t('buyFgWinBoxElevateFromKey')}}
                Col.val(span="8" offset="6")
                  Input(
                    v-model="condition.buyFgWinBoxElevateFromKey"
                    :placeholder="$t('buyFgWinBoxElevateFromKey')"
                  )
              Row.condition-row(align="middle" style="margin-bottom: 10px")
                Col.title(span="10")
                  h3 {{$t('buyFgWinBoxElevateToKey')}}
                Col.val(span="8" offset="6")
                  Input(
                    v-model="condition.buyFgWinBoxElevateToKey"
                    :placeholder="$t('buyFgWinBoxElevateToKey')"
                  )
      .content(v-if="type === 'chanceBox'")
        .con(v-show="currentTab === name || previousTab === name")
          Row(:gutter="20")
            Col(span="18")
              Alert(type="warning" class="tip" v-if="!description") {{ getAlert(name) }}
              Alert(type="warning" class="tip" v-if="description") {{ $t(description) }}
            Col.text-align-right(span="6")
              Tag(size="large") {{ $t('RTP') }} {{ calculateRtp(name, getNameObjectKeys(name, keyName), odds) }}
          Row(:gutter="20"
            v-for="(symbol, idx) in getNameObjectKeys(name, keyName)"
            :key="`${symbol}-${idx}`"
            align="middle"
          )
            Col(span="4")
              h4.text-middle(
                v-if="!submenu"
              )
                //- span {{ symbol }}
                Input(
                  type="text"
                  v-model="getNameObjectKeys(name, keyName)[idx]"
                  style="float: left; width: 52px"
                  :disabled="viewMode"
                )
              h4.text-middle.pointer(
                v-if="submenu"
                @click="toggleSubmenu(submenu, symbol)"
              )
                //- span {{ symbol }}
                Input(
                  type="text"
                  v-model="getNameObjectKeys(name, keyName)[idx]"
                  style="float: left; width: 52px"
                  :disabled="viewMode"
                )
                Icon(
                  type="md-arrow-dropdown"
                  style="float: left; margin: 12px 8px;"
                )
            Col(span="11")
              Slider(
                v-model="getNameObjectValues(name)[idx]"
                :min="0"
                :max="getSliderMax(getNameObjectValues(name))"
                :step="sliderStep"
                @on-input="onSliderChange"
                :disabled="viewMode"
              )
            Col(span="3")
              InputNumber(
                :step="sliderStep"
                v-model="getNameObjectValues(name)[idx]"
                :disabled="viewMode"
                style="width: 62px;"
              )
            Col(span="3")
              span(style="float: right;") {{ symbolPercent(name, idx)}}
            Col(span="3")
              Icon.add-key-btn(
                type="md-add"
                v-if="idx !== getNameObjectValues(name).length - 1"
                @click="addKeyValues(name, keyName, idx, submenu)"
              )
              Icon.remove-key-btn(
                type="md-remove"
                v-if="idx !== 0"
                @click="removeKeyValues(name, keyName, idx, submenu)"
              )
            Col.submenu(span="24" v-if="showMyMenu(submenu, symbol)")
              Row(
                :gutter="20"
                v-for="(row, index) in formData[submenu][symbol]"
                :key="index"
                align="middle"
                style="margin: 4px 0"
              )
                Col(span="3")
                  h4(class="text-middle") {{ $t('{number} erase', { number: index + 1 }) }}
                Col(span="4")
                  InputNumber(
                    :step="sliderStep"
                    v-model="formData[submenu][symbol][index]"
                    :disabled="viewMode"
                  )
                Col(span="5")
                  span {{ subPercent(formData[submenu][symbol], index)}}
                  .btn-group(
                    v-if="index === formData[submenu][symbol].length - 1"
                    style="float: right;"
                  )
                    Icon.add-key-btn(
                      type="md-add"
                      @click="addSubmenu(formData[submenu][symbol], index)"
                    )
                    Icon.remove-key-btn(
                      type="md-remove"
                      @click="removeSubmenu(formData[submenu][symbol], index)"
                    )

      .content(v-if="type === 'levelMap'")
        .con(v-show="currentTab === name || previousTab === name")
          Alert(type="warning" class="tip" v-if="!description") {{ getAlert(name) }}
          Alert(type="warning" class="tip" v-if="description") {{ $t(description) }}
          Row(:gutter="12")
            Col(span="12")
              h2(style="padding: 16px") {{ $t("mgHitBoxLevelMap") }}
              Row.level-item(
                :gutter="12"
                v-for="(times, idx) in Object.keys(formData['mgHitBoxLevelMap'])"
                :key="`mg-lv-${times}-${idx}`"
              )
                Col(span="6" offset="1")
                  h3.text-align-right {{ times }}
                Col(span="12")
                  Select.app-tool(
                    v-model="formData['mgHitBoxLevelMap'][times]"
                    style="width:180px"
                  )
                    Option(v-for="(item, idx) in mgHitBoxLevels" :value="item" :key="`${item}-${idx}`") {{ item }}
            Col(span="12")
              h2(style="padding: 16px") {{ $t("fgHitBoxLevelMap") }}
              Row.level-item(
                :gutter="12"
                v-for="(times, idx) in Object.keys(formData['fgHitBoxLevelMap'])"
                :key="`mg-lv-${times}-${idx}`"
              )
                Col(span="6" offset="1")
                  h3.text-align-right {{ times }}
                Col(span="12")
                  Select.app-tool(
                    v-model="formData['fgHitBoxLevelMap'][times]"
                    style="width:180px"
                  )
                    Option(v-for="(item, idx) in fgHitBoxLevels" :value="item" :key="`${item}-${idx}`") {{ item }}

      .content(v-if="type === 'chanceBoxTree'")
        .con(v-show="currentTab === name || previousTab === name")
          Row(:gutter="20")
            Col(span="8")
              Select.app-tool(
                v-model="boxTreeOptions[name]"
                @on-change="onTreeOptionChange"
                style="width:180px"
              )
                Option(v-for="(item, idx) in Object.keys(formData[name])" :value="item" :key="`${item}-${idx}`") {{ item }}
            Col(span="16")
              Alert(type="warning" class="tip" v-if="!description") {{ getAlert(name) }}
              Alert(type="warning" class="tip" v-if="description") {{ $t(description) }}

          Row(:gutter="20"
            v-for="(symbol, index) in formData[name][boxTreeOptions[name]]"
            :key="index"
            align="middle"
          )
            Col(span="3")
              h4(class="text-middle") {{ formData[keyName][index] }}
            Col(span="13")
              Slider(
                v-model="formData[name][boxTreeOptions[name]][index]"
                :min="0"
                :max="getSliderMax(formData[name][boxTreeOptions[name]])"
                :step="sliderStep"
                @on-input="onSliderChange"
                :disabled="viewMode"
              )
            Col(span="3")
              InputNumber(
                :step="sliderStep"
                v-model="formData[name][boxTreeOptions[name]][index]"
                :disabled="viewMode"
              )
            Col(span="5")
              span(style="float: right;") {{ symbolTreePercent(name, index)}}

      .content(v-if="type === 'parameter'")
        Form.modal-editor(
          :model="formData"
          v-show="currentTab === name || previousTab === name"
        )
          template
            .content-part
              template(v-for="item in keys")
                FormItem(:prop="item.name")
                  Row(align="middle" v-if="item.type === 'line'")
                    Col.title(span="24")
                      Divider
                  Row(align="middle" v-if="item.type !== 'line'")
                    Col.title(span="10")
                      h3 {{ $t(item.name) }}
                    Col.val(span="8" offset="6")
                      InputNumber(
                        v-if="item.hasOwnProperty('min')"
                        style="width: 100%;"
                        v-model="formData[item.name]"
                        :placeholder="$t(item.name)"
                        :step="getStep(item.min)"
                        :disabled="viewMode"
                      )

                      i-switch(
                        v-if="item.switch"
                        v-model="formData[item.name]"
                        :true-value="true"
                        :false-value="false"
                        :disabled="viewMode"
                      )
                        Icon(slot="open" type="md-checkmark")
                        Icon(slot="close" type="'md-close")

                      Input(
                        v-if="item.hasOwnProperty('string')"
                        v-model="formData[item.name]"
                        style="width: 100%;"
                      )

                      Select.text-left(
                        v-if="item.options"
                        v-model="formData[item.name]"
                        :disabled="viewMode"
                      )
                        Option(v-for="(val, index) in item.options" :value="val" :key="`${index}-${val}`" :label="$t(`dropDownMenu.${val}`)")

  .footer(slot='footer')
    Row
      Col(span="6")

      Col(span="18")
        Button(icon="md-close" @click="onClose" :disabled="isUpdating") {{$t('Cancel')}}
        Button(
          v-if="!viewMode"
          icon="md-checkmark"
          type="primary"
          @click="winBoxConfirm"
          :loading="isUpdating"
          ) {{$t('Confirm')}}

</template>
<script>
import NumberUtil from '~m/number-util'
import Thumbnail from '~m/thumbnail'
import AppTable from '~c/app-table'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'win-box-editor',
  components: {
    AppTable
  },
  data () {
    return {
      sliderStep: 1000,
      currentTab: null,
      previousTab: null,
      isUpdating: false,
      showCondition: {},
      showSubmenu: {},
      boxTreeOptions: {},
      fgHitBoxLevels: [
        'level1',
        'level2',
        'level3',
        'level4',
        'level5'
      ],
      mgHitBoxLevels: [
        'level1',
        'level2',
        'level3',
        'level4'
      ],
      templateList: [
        { value: false, key: 'DefaultTemplate' },
        { value: false, key: 'RandomTemplate' },
        { value: false, key: 'RtpTemplate' }
      ],
      templateName: '',
      templateOp: [],
      minLimit: 0,
      metaCheckList: [
        {
          name: 'fgEnterRate',
          min: 0,
          max: 0.02
        },
        {
          name: 'fgMaxEnterRate',
          min: 0,
          max: 0.02
        },
        {
          name: 'mgHighWinChance',
          min: 0,
          max: 0.02
        },
        {
          name: 'mgNoWinChance',
          min: 0,
          max: 0.9
        },
        {
          name: 'mgNoWinChanceLimit',
          min: 0,
          max: 0.99
        },
        {
          name: 'poolRate',
          min: 0,
          max: 0.02
        }
      ],
      showTagInput: null,
      tagInputText: null
    }
  },
  mixins: [NumberUtil, Thumbnail],
  props: {
    formData: Object,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean,
    viewMode: Boolean
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) {
          this.boxTreeOptions = {}
          this.currentTab = null
          return
        }

        this.parameters = {}
        this.parseObjectNumber(this.formData)

        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }

        if (!this.currentTab && this.formData.tabs) {
          this.currentTab = this.formData.tabs[0].name
        }

        if (this.formData.tabs) {
          for (const tab of this.formData.tabs) {
            const { name, type, submenu, keys } = tab
            if (type === 'chanceBoxTree') {
              if (!this.boxTreeOptions[name]) {
                this.boxTreeOptions[name] = Object.keys(this.formData[name])[0]
              }
            }

            if (submenu && !this.showSubmenu[submenu]) {
              for (const key of Object.keys(this.formData[submenu])) {
                if (!this.showSubmenu[submenu]) this.showSubmenu[submenu] = {}
                this.showSubmenu[submenu][key] = false
              }
            }

            if (name === 'others') {
              for (const keyData of keys) {
                const { name } = keyData
                if (!this.formData.hasOwnProperty(`${name}`)) {
                  if (keyData.hasOwnProperty('default')) {
                    this.formData[`${name}`] = keyData.default
                  } else if (keyData.hasOwnProperty('max') && keyData.max > 0) {
                    this.formData[`${name}`] = keyData.max
                  } else if (keyData.hasOwnProperty('min') && keyData.min === 0) {
                    this.formData[`${name}`] = keyData.min
                  }
                  if (this.formData.hasOwnProperty(`${name}`)) {
                    this.formData.isDirty = true
                  }
                }
              }
            }
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    tabs () {
      if (!this.formData) return []
      // 如果 type not game 代表機率表是 operator 的
      // operator 就不顯示遊戲攻略, 只有 game 的機率表才顯示
      // if (this.formData.type !== 'game') {
      //   // 過濾掉遊戲攻略的 tab
      //   return this.formData.tabs.filter(tab => tab.type !== 'signalCondition')
      // }
      // console.log('this.formData.game: ', this.formData.game)
      return this.formData.tabs
    },
    boxEditorRule () {
      const rules = {}
      for (const { name } of this.metaCheckList) {
        rules[name] = [{ required: true, validator: this.numberValidator, trigger: 'blur' }]
      }
      return rules
    },
    operatorId () {
      if (!this.$route.query.operator) return null
      return parseInt(this.$route.query.operator, 10)
    },
    isBetaOperator () {
      const operator = _.find(this.allOperators, { id: this.operatorId })
      if (!operator) return false
      return operator.isBeta
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('oddsTemplate', {
      createTemplate: 'create'
    }),
    getNameObjectKeys (name, keyName) {
      return (keyName ? this.formData[keyName] : this.formData[`${name}Keys`])
    },
    getNameObjectValues (name) {
      return (this.formData[`${name}Values`] ? this.formData[`${name}Values`] : this.formData[name])
    },
    addKeyValues (name, keyName, index, submenu) {
      const keys = this.getNameObjectKeys(name, keyName)
      const values = this.getNameObjectValues(name, keyName)
      const [v1, v2] = [parseInt(keys[index]), parseInt(keys[index + 1])]
      const key = v1 + ((v2 - v1) / 2)
      keys.splice(index + 1, 0, key)
      values.splice(index + 1, 0, values[index])
      // if (submenu) {
      //   const sub = this.formData[submenu][`${v1}`]
      //   this.formData[submenu][`${key}`] = sub
      // }
    },
    removeKeyValues (name, keyName, index, submenu) {
      const keys = this.getNameObjectKeys(name, keyName)
      const values = this.getNameObjectValues(name, keyName)
      keys.splice(index, 1)
      values.splice(index, 1)
    },
    removeSignalCondition (idx) {
      if (idx > -1) {
        this.formData.signalConditions.splice(idx, 1)
      }
    },
    addSignalCondition () {
      // 新增遊戲攻略
      const newCondition = {
        hitBox: 1,
        singalSymbol: 1,
        singalSymbolAmountAtLeast: 6,
        singalSymbolAmountAtMost: 7,
        buyFgWinBoxElevateRatio: 1,
        buyFgWinBoxElevateFromKey: 100,
        buyFgWinBoxElevateToKey: 100
      }
      this.formData.signalConditions.push(newCondition)
    },
    toggleCondition (idx) {
      this.showCondition[idx] = !this.showCondition[idx]
      this.$forceUpdate()
    },
    isShowCondition (idx) {
      if (idx < 0) {
        return false
      }
      return this.showCondition[idx]
    },
    toggleSubmenu (menu, key) {
      if (!menu) return
      const val = this.formData[menu][key]
      if (!val) {
        const keys = Object.keys(this.formData[menu])
        this.formData[menu][key] = this.formData[menu][keys[0]]
      }
      this.showSubmenu[menu][key] = !this.showSubmenu[menu][key]
      this.$forceUpdate()
    },
    showMyMenu (menu, key) {
      if (!menu) return false
      return this.showSubmenu[menu][key]
    },
    calculateRtp (name, keys, odds = 1) {
      if (!keys) return null

      let rtp = 0
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const rate = this.symbolPercent(name, i)
        rtp += Number(key) * Number(rate.replace('%', '')) / 100
      }

      if (/^mgNormal.+/.test(name)) {
        const fgEnterRate = (this.formData.fgMaxEnterRate + this.formData.fgEnterRate) / 2
        const winChance = 1 - this.formData.mgNoWinChanceLimit - fgEnterRate
        rtp *= winChance
      } else if (/^fg.+/.test(name)) {
        rtp *= this.formData.fgMaxEnterRate
      } else if (/^fortuneLanding.+/.test(name)) {
        rtp *= this.formData.fortuneLandingChance
      } else if (/^luckyWealth.+/.test(name)) {
        rtp *= this.formData.luckyWealthChance
      } else if (/^jpGame.+/.test(name)) {
        rtp *= this.formData.jpGameChance
      } else if (/^mgFullCombo.+/.test(name)) {
        rtp *= this.formData.mgFullComboChance
      } else if (/^elixirFurnace.+/.test(name)) {
        rtp *= this.formData.elixirFurnaceChance
      } else if (/^mgNearFullCombo.+/.test(name)) {
        rtp *= this.formData.mgNearFullComboChance
      }

      rtp /= odds
      return rtp.toFixed(3)
    },
    getSliderMax (myValues) {
      if (_.isObject(myValues)) {
        myValues = _.values(myValues)
      }
      let maxVal = _.max(myValues)
      if (maxVal < 1000) {
        maxVal = 1000
      } else {
        maxVal += 1000
      }
      return maxVal
    },
    getTabTitle (name, method) {
      let title = this.$t(`tabTitle.${name}`)
      if (method === 'odds') {
        title += this.$t(`box-${method}`)
      }
      return title
    },

    subPercent (data, index) {
      const total = _.sum(data)
      return (data[index] / total * 100).toFixed(3) + '%'
    },

    addSubmenu (submenu, index) {
      const val = submenu[index]
      submenu.splice(index, 0, val)
    },

    removeSubmenu (submenu, index) {
      submenu.splice(index, 1)
    },

    symbolPercent (name, index) {
      const data = this.formData[`${name}Values`] ? this.formData[`${name}Values`] : this.formData[name]
      const total = _.sum(data)
      return (data[index] / total * 100).toFixed(3) + '%'
    },
    singalPercent (index) {
      const data = this.formData.signalConditions.map(s => s.hitBox)
      const total = _.sum(data)
      return (data[index] / total * 100).toFixed(3) + '%'
    },
    symbolTreePercent (name, index) {
      const data = this.formData[name][this.boxTreeOptions[name]]
      const total = _.sum(data)
      return (data[index] / total * 100).toFixed(3) + '%'
    },

    onTreeOptionChange (val) {
      // console.log(val)
      // console.log('this.currentTab', this.currentTab)
      // this.boxTreeOptions[this.currentTab] = val
      // console.log('this.formData[this.currentTab][val]', this.formData[this.currentTab][val])
      // this.formData[this.currentTab][val] = [...this.formData[this.currentTab][val]]
      this.$forceUpdate()
    },

    getStep (val) {
      const str = `${val}`.split('.')
      if (str.length === 1) {
        return Number(val || 1)
      } else {
        return Number(val)
      }
    },
    setParameter (key, value) {
      this.$set(this.parameters, key, value)
    },
    async winBoxConfirm () {
      if (!this.isBetaOperator && process.env.VUE_APP_ENV === 'production') {
        for (const { name, max, min } of this.metaCheckList) {
          const value = this.formData[name]
          if (value > max) {
            return this.$Message.error(this.$t('Parameter {key} exceed the Maximum Area {value}', { key: this.$t(name), value: max }))
          } else if (value < min) {
            return this.$Message.error(this.$t('Parameter {key} exceed the Minimum Area {value}', { key: this.$t(name), value: min }))
          }
        }
      }
      this.isUpdating = true
      await this.onUpdate()
    },

    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }

      this.formData.isReady = true
    },

    onSliderChange (val) {
      this.formData.isDirty = true
    },

    numberValidator (rule, value, callback) {
      const { field: name } = rule
      if (this.isBetaOperator) return callback()
      if (value === '') {
        callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value)) {
        callback(new Error(this.$t('This field accept only numbers')))
      }
      let field = _.find(this.metaCheckList, { name })
      const { max, min } = field
      if (value > max) {
        callback(new Error(this.$t('Exceed the Maximum Area {value}', { value: max })))
      } else if (value < min) {
        callback(new Error(this.$t('Exceed the Minimum Area {value}', { value: min })))
      }

      callback()
    },
    getTap (tab) {
      let hasTab = _.find(this.tabs, p => p.name === tab) !== undefined
      return hasTab
    },
    getAlert (tab) {
      let Alert = _.find(this.tabs, p => p.name === tab) || 'error'
      return this.$t(`${Alert.method} Tips`)
    },
    onChangeTab (tab) {
      this.previousTab = this.currentTab
      this.currentTab = tab
      setTimeout(() => {
        this.previousTab = null
      }, 500)
    },
    tagColor (item) {
      switch (item) {
        case 'DefaultTemplate':
          return 'primary'
        case 'RtpTemplate':
          return 'error'
        case 'RandomTemplate':
          return 'warning'
        default:
          return 'primary'
      }
    },
    changeTag (tpList) {
      let { key } = tpList
      _.map(this.templateList, p => {
        if (p.key === key) {
          p.value = !p.value
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.edit-title
  height: 40px
  p
    height: auto

.rtp-list
  // padding: 4px 12px
  // width: auto
  // float: left
  .stake-level
    font-weight: bold
    margin-right: 8px
    width: 40px
    text-align: right
    display: inline-block

  .stake-rtp
    width: 48px
    text-align: right
    display: inline-block

.current-rtp, .text-red
  // font-weight: bold
  color: #ed4014

.box-rate-alert
  font-weight: bold
  color: #ed4014
  text-align: right

.target-rtp
  color: #c5c8ce

.rtp-divider
  margin: 0 4px

.pull-right
  float: right

.text-left
  text-align: left

.chance-input
  text-align: right
  padding-right: 4px

.margin-top-6
  margin-top: 6px

.margin-bottom-6
  margin-bottom: 6px

.mg-box-con
  width: 65px
  float: left

.text-middle
  text-align: right
  vertical-align: middle
  &.pointer
    cursor: pointer

.tip
  margin-bottom: 24px

.content
  width: 100%
  min-height: 50%

.tag-border
  border: 1px solid #dddfe3!important

.text-align-right
  text-align: center

.level-item
  margin: 12px

.submenu
  padding: 16px 0
  background-color: #f8f8f9

.condition-title
  margin-left: 10px
  margin-right: 10px
  margin-top: 2px

.condition-row
  margin-left: 10px
  margin-right: 10px
  margin-top: 10px

.condition-view
  margin-left: 10px
  margin-right: 10px
  margin-top: 16px
  margin-bottom: 20px

.add-key-btn, .remove-key-btn
  margin-left: 4px
  font-size: 16px
  cursor: pointer
  &.add-key-btn
    color: #19be6b
    &:hover
      color: darken(#19be6b, 30%)
  &.remove-key-btn
    color: #ed4014
    &:hover
      color: darken(#ed4014, 30%)
</style>
